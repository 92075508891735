import $ from 'jquery';

function requireAll(r) {
    r.keys().forEach(r);
}

requireAll(require.context('../icons', true, /\.svg$/));

class Global {
    init() {
        window.addEventListener('load', () => {
            $('.loading-overlay').addClass('hide');
        });
    }
}

export default Global;
