import $ from 'jquery';

const isRTL = ($('html').attr('dir') === 'rtl');

const initBannerSlider = (target) => {
    const sliderContainer = $(target);
    const sliderSlides = sliderContainer.find('.js-slider'); // $('.js-slider')
    const sliderIndicator = sliderContainer.find('.js-slickIndicator'); // $('.js-slickIndicator')
    const sliderArrows = sliderContainer.find('.js-sliderArrow'); // $('.js-sliderArrow')
    const asNavFor = $('.js-bannerSlider_bg, .js-bannerSlider_paragraph');
    let curSlide;
    let totalSlide;
    let getPrecentage;

    const bannerPrev = `<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 27.4 47.9" style="enable-background:new 0 0 27.4 47.9;" xml:space="preserve" class="toSvg replaced-svg">
        <path id="Path_1" d="M23.9,0l3.5,3.5L6.8,23.9l20.6,20.4l-3.5,3.5L0,23.9L23.9,0"></path>
        </svg></button>`;
    const bannerNext = `<button class="slick-next slick-arrow" aria-label="Next" type="button"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 27.4 47.9" style="enable-background:new 0 0 27.4 47.9;" xml:space="preserve" class="toSvg replaced-svg">
        <path id="Path_1" d="M3.5,47.9L0,44.4l20.6-20.4L0,3.5L3.5,0l23.9,24L3.5,47.9"></path>
        </svg></button>`;

    sliderSlides.on('init', (event, slick) => {
        curSlide = slick.currentSlide + 1;
        totalSlide = slick.slideCount;
        getPrecentage = (curSlide / totalSlide) * 100;
        sliderIndicator.find('.slick-progress').find('span').width(`${getPrecentage}%`);
    });

    sliderSlides.slick({
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 8000,
        dots: true,
        arrows: true,
        fade: true,
        draggable: true,
        infinite: true,
        focusOnSelect: true,
        rtl: isRTL,
        appendDots: sliderIndicator,
        appendArrows: sliderArrows,
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        asNavFor,
        adaptiveHeight: true
    });

    sliderSlides.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        curSlide = nextSlide + 1;
        totalSlide = slick.slideCount;
        getPrecentage = (curSlide / totalSlide) * 100;
        sliderIndicator.find('.slick-progress').find('span').width(`${getPrecentage}%`);
    });
};

const initSlider = () => {
    $('.js-bannerSlider_bg').slick({
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        nextArrow: '.slide-right',
        prevArrow: '.slide-left',
        draggable: true,
        infinite: true,
        fade: true,
        rtl: isRTL,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    });

    // $('.js-bannerSlider_content').each(function initBannerSliderEach() {
    //    initBannerSlider($(this));
    // });
};

const init = () => {
    initSlider();
};

export default init;
