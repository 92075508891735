import $ from 'jquery';

window.JQuery = $;

const initializeCarousel = () => {
    $('.partners-carousel').slick({
        arrows: false,
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 10,
        cssEase: 'linear',
        // cssEase: 'ease-out',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        // pauseOnFocus: false,
        // pauseOnHover: false,
        draggable: false,
        rtl: document.documentElement.dir === 'rtl',
        responsive: [
            {
                breakpoint: 992,
                settings: 'unslick'
            }
        ]
    });

    // $('.partners-carousel').on('mouseenter', '.partners-carousel-item', (e) => {
    //     console.log('mouseenter', {
    //         currentTarget: e.currentTarget,
    //         carousel: $(e.currentTarget).closest('.partners-carousel')
    //     });
    //     $(e.currentTarget).closest('.partners-carousel').slick('pause');
    // });

    // $('.partners-carousel').on('mouseleave', '.partners-carousel-item', (e) => {
    //     console.log('mouseleave', {
    //         currentTarget: e.currentTarget,
    //         carousel: $(e.currentTarget).closest('.partners-carousel')
    //     });
    //     $(e.currentTarget).closest('.partners-carousel').slick('play');
    // });

    // $('.partners-carousel').each((index, element) => {
    //     $(element).slick({
    //         arrows: false,
    //         speed: 4000,
    //         autoplay: true,
    //         autoplaySpeed: 0,
    //         cssEase: 'linear',
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         variableWidth: true,
    //         centerMode: true,
    //         rtl: document.documentElement.dir === 'rtl',
    //         responsive: [
    //             {
    //                 breakpoint: 992,
    //                 settings: 'unslick'
    //             }
    //         ]
    //     });
    // });

    $('.speakers-carousel').slick({
        arrows: true,
        dots: true,
        infinite: false,
        cssEase: 'linear',
        // fade: true,
        rtl: document.documentElement.dir === 'rtl',
        prevArrow: $('.speakers-carousel-container .speakers-carousel-prev-arrow'),
        nextArrow: $('.speakers-carousel-container .speakers-carousel-next-arrow')
    });

    $('.videos-carousel').each((index, element) => {
        $(element)
            .slick({
                arrows: true,
                dots: true,
                infinte: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                rtl: document.documentElement.dir === 'rtl',
                nextArrow: $(element).parent().find('.videos-carousel-next-arrow'),
                prevArrow: $(element).parent().find('.videos-carousel-prev-arrow'),
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
    });

    const onChangeTestimonialCarouselSlide = (slick, slide) => {
        const $currentSlide = $(slick.$slides[slide]);
        const titleBackground = $currentSlide.find('[data-title-background]').attr('data-title-background');
        const backgroundArt = $currentSlide.closest('.testimonials-carousel-container').find('.background-art')[0];

        if (backgroundArt)
            backgroundArt.style.backgroundColor = titleBackground || '#f5c55a';
    };

    $('.testimonials-carousel').each((index, element) => {
        $(element)
            .on('init', (e, slick) => {
                onChangeTestimonialCarouselSlide(slick, slick.currentSlide);
            })
            .slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '80px',
                variableWidth: true,
                rtl: document.documentElement.dir === 'rtl',
                dots: true,
                arrows: true,
                prevArrow: $(element).parent().find('.testimonials-carousel-prev-arrow'),
                nextArrow: $(element).parent().find('.testimonials-carousel-next-arrow'),
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            centerMode: false,
                            variableWidth: false,
                            dots: true,
                            arrows: false
                        }
                    }
                ]
            })
            .on('beforeChange', (e, slick, currentSlide, nextSlide) => {
                onChangeTestimonialCarouselSlide(slick, nextSlide);
            });
    });

    $('.testimonials-bigcard-carousel').each((index, element) => {
        $(element).slick({
            asNavFor: '.testimonials-card-carousel, .testimonials-quote-carousel',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            rtl: document.documentElement.dir === 'rtl',
            dots: false,
            arrows: true,
            swipe: false,
            prevArrow: $(element).closest('.testimonials-card-carousel-container').find('.testimonials-card-carousel-prev-arrow'),
            nextArrow: $(element).closest('.testimonials-card-carousel-container').find('.testimonials-card-carousel-next-arrow'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        dots: true,
                        arrows: false,
                        swipe: true
                    }
                }
            ]
        });
    });

    $('.testimonials-quote-carousel').each((index, element) => {
        $(element).slick({
            asNavFor: '.testimonials-card-carousel, .testimonials-bigcard-carousel',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            rtl: document.documentElement.dir === 'rtl',
            dots: false,
            arrows: false,
            swipe: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        swipe: true
                    }
                }
            ]
        });
    });

    $('.testimonials-card-carousel').each((index, element) => {
        $(element).slick({
            asNavFor: '.testimonials-bigcard-carousel, .testimonials-quote-carousel',
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false,
            rtl: document.documentElement.dir === 'rtl',
            dots: false,
            arrows: false,
            swipe: false,
            responsive: [
                {
                    breakpoint: 1719,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 992,
                    settings: 'unslick'
                }
            ]
        });
    });
};

const init = () => {
    initializeCarousel();
};

export default init;
