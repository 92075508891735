/* eslint-disable max-len */
import $ from 'jquery';

const initExpSliders = (target) => {
    const sliderContainer = $(target);
    const sliderSlides = sliderContainer.find('.js-slider'); // $('.js-slider')
    const sliderIndicator = sliderContainer.find('.js-slickIndicator'); // $('.js-slickIndicator')
    const sliderArrows = sliderContainer.find('.js-sliderArrow'); // $('.js-sliderArrow')
    const asNavFor = $('.js-imgExpSlider, .js-slider1');

    let bannerPrev = null;
    let bannerNext = null;

    if (document.documentElement.dir === 'rtl') {
        bannerPrev = '<button class="slick-arrow" aria-label="Previous" type="button"><svg class="toSvg" width="7px" height="14px"><use xlink:href="/images/icons.svg#ic-right-arrow"></use></svg></button>';
        bannerNext = '<button class="slick-arrow" aria-label="Next" type="button"><svg class="toSvg" width="7px" height="14px"><use xlink:href="/images/icons.svg#ic-left-arrow"></use></svg></button>';
    } else {
        bannerPrev = '<button class="slick-arrow" aria-label="Previous" type="button"><svg class="toSvg" width="7px" height="14px"><use xlink:href="/images/icons.svg#ic-left-arrow"></use></svg></button>';
        bannerNext = '<button class="slick-arrow" aria-label="Next" type="button"><svg class="toSvg" width="7px" height="14px"><use xlink:href="/images/icons.svg#ic-right-arrow"></use></svg></button>';
    }

    sliderSlides.on('init', (event, slick) => {
        const curSlide = slick.currentSlide + 1;
        const totalSlide = slick.slideCount;
        const getPrecentage = (curSlide / totalSlide) * 100;
        sliderIndicator
            .find('.slick-progress')
            .find('span')
            .width(`${getPrecentage}%`);
    });

    sliderSlides.slick({
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        fade: true,
        draggable: true,
        infinite: true,
        focusOnSelect: true,
        adaptiveHeight: true,
        rtl: document.documentElement.dir === 'rtl',
        appendDots: sliderIndicator,
        appendArrows: sliderArrows,
        prevArrow: bannerPrev,
        nextArrow: bannerNext,
        asNavFor,
        customPaging(slider, i) {
            let currentSlideLabel = '0';
            let totalSlidesLabel = '0';

            if (i > 8)
                currentSlideLabel = '';

            if (slider.slideCount > 8)
                totalSlidesLabel = '';

            return `${currentSlideLabel + (i + 1) }<small>/${ totalSlidesLabel }${slider.slideCount }</small>`;
        }
    });

    sliderSlides.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        const curSlide = nextSlide + 1;
        const totalSlide = slick.slideCount;
        const getPrecentage = (curSlide / totalSlide) * 100;
        sliderIndicator
            .find('.slick-progress')
            .find('span')
            .width(`${getPrecentage }%`);
    });
};

const init = () => {
    $('.js-imgExpSlider').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        draggable: true,
        infinite: true,
        fade: true,
        rtl: document.documentElement.dir === 'rtl',
        asNavFor: '.js-ExpcontentSlider .js-slider, .js-slider1'
    });

    $('.js-slider1').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        draggable: true,
        infinite: true,
        fade: true,
        rtl: document.documentElement.dir === 'rtl',
        asNavFor: '.js-ExpcontentSlider .js-slider, .js-imgExpSlider'
    });

    $('.js-ExpcontentSlider').each((index, element) => {
        initExpSliders($(element));
    });
};

export default init;
