import $ from 'jquery';

// eslint-disable-next-line import/no-unused-modules
const validateLoadMore = (buttonElement) => {
    const hiddenRowsCount = $(buttonElement).closest('.cards-list.cards-list-preloaded').children(':hidden').length;

    if (hiddenRowsCount === 0)
        $(buttonElement).hide();
};

const init = () => {
    $(() => {
        $('.cards-list.cards-list-preloaded .button-load-more').each((index, buttonElement) => {
            validateLoadMore(buttonElement);
        });

        $('.cards-list.cards-list-preloaded .button-load-more').on('click', (e) => {
            const pageRowCount = parseInt($(e.currentTarget).closest('.cards-list.cards-list-preloaded').attr('data-page-row-count') || 2, 10);

            $(e.currentTarget)
                .closest('.cards-list.cards-list-preloaded')
                .children(':hidden')
                .slice(0, pageRowCount)
                .show();

            validateLoadMore(e.currentTarget);
        });
    });
};

export default init;
