import $ from 'jquery';
import Plyr from 'plyr';

const init = () => {
    $('.card .plyr').each((index, element) => {
        const player = new Plyr(element);

        if (player)
            $(element).attr('data-player-initialized', 'true');
    });

    if ($('.card .card-video-play').length)
        $('.card .card-video-play').magnificPopup({ type: 'iframe' });

    if ($('.video .video-play').length)
        $('.video .video-play').magnificPopup({ type: 'iframe' });
};

export default init;
