import $ from 'jquery';

const init = () => {
    const root = document.getElementsByTagName('html')[0];

    $('.full-screen-modal').on('click', '.modal-close-button', (e) => {
        $(e.currentTarget).closest('.full-screen-modal').removeClass('modal-open');
        $('html').removeClass('o-hidden');
    });

    window.openModal = (selector) => {
        const modal = $(selector);

        if (modal.hasClass('full-screen-modal')) {
            modal.addClass('modal-open');
            $('html').addClass('o-hidden');
        }
    };
};

export default init;
