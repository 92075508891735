import $ from 'jquery';

const isMobileMenu = window.innerWidth < 991;
const initHeader = () => {
    $(window).on('scroll', () => {
        const currScrollTop = $(window).scrollTop();
        const isHome = $('header').attr('data-type') === 'home';

        if (isHome) {
            if (currScrollTop > 1) {
                $('.navbar').removeClass('navbar-light');
                $('.navbar').addClass('navbar-dark');
                $('.side-bar').addClass('side-bar-dark');
            } else if (currScrollTop < 108) {
                $('.navbar').addClass('navbar-light');
                $('.navbar').removeClass('navbar-dark');
                $('.side-bar').removeClass('side-bar-dark');
            }
        }
    });

    $('.dropdown').on('mouseover', function () {
        if (!isMobileMenu) {
            $(this).addClass('show');
            $(this).find('.dropdown-menu').addClass('show');
            $('.dropdown-toggle').attr('aria-expanded', 'true');
        }
    });
    $('.dropdown .menu-item-expand-arrow').on('click', function () {
        if (isMobileMenu) {
            $(this).parent().toggleClass('show');
            $(this).parent().find('.dropdown-menu').toggleClass('show');
        }
    });

    $('.dropdown').on('mouseout', function () {
        if (!isMobileMenu) {
            $(this).removeClass('show');
            $(this).find('.dropdown-menu').removeClass('show');
            $('.dropdown-toggle').attr('aria-expanded', 'false');
        }
    });

    $('.navbar-search-toggle-button').on('click', () => {
        $('body').toggleClass('search-open');
        $('.search-results').html('');
    });

    $('.search-toggler').on('click', () => {
        $('body').removeClass('search-open');
        $('.search-results').html('');
    });

    $('.navbar-search-field').on('input', function () {
        const hasText = (this.value || '').trim().length > 0;

        if (hasText)
            $('body').addClass('search-active');
        else
            $('body').removeClass('search-active');
    });

    const timelineImage = $('.timeline img');

    function checkForWindowResize() {
        if (window.innerWidth <= 768) {
            if (timelineImage)
                timelineImage.attr('src', '/images/timeline-mobile.png');
        } else {
            $('.dropdown-menu, .site-navbar').removeClass('show');

            if (timelineImage)
                timelineImage.attr('src', '/images/timeline.png');
        }
    }

    window.addEventListener('resize', checkForWindowResize);
    checkForWindowResize();

    const path = window.location.pathname;
    let link = [ '/', '/en/', '/ar/' ].includes(path) ? path : path.substring(0, path.length - 5);

    if (link && link.length > 1 && link[link.length - 1] === '/')
        link = link.slice(0, link.length - 1);

    const navlink = document.querySelector(`.navbar-nav [href^='${link}']`);

    if (navlink)
        navlink.classList.add('active');
};

// eslint-disable-next-line no-unused-vars
const initSearch = () => {
    $('.navbar-search form').on('submit', (e) => {
        e.preventDefault();
        // $('.search-results').html(`
        // <li class="search-results-item">
        //     <a href="/">2023 SPEAKERS</a>
        //     <span>Events > IAD 2023</span>
        // </li>
        // <li class="search-results-item">
        //     <a href="/">2023 SPEAKERS</a>
        //     <span>Events > IAD 2023</span>
        // </li>
        // `);
    });
};

export default class Header {
    init() {
        initHeader();
        initSearch();
    }
}
