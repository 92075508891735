import $ from 'jquery';

const shadowAnimation = (ele) => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    const isMobile = window.innerWidth < 991;

    if (!mediaQuery.matches && !isMobile) {
        setTimeout(() => {
            $(window).scrollTop(0);

            const $this = $(ele);
            let ticking = false;

            const update = () => {
                ticking = false;

                const windowHeight = $(window).height();
                const scrollTop = $(window).scrollTop();

                $this.each((index, element) => {
                    const sectionHeading = $(element);
                    const textElement = sectionHeading.find('.section-heading-text');
                    const shadowElement = sectionHeading.find('.section-heading-shadow');
                    const textElementOffset = textElement.offset().top;
                    const viewportPosition = ((textElementOffset - scrollTop) / windowHeight);

                    // If text is visible in viewport (0-1), change shadow vertical offset based on current scroll
                    if (viewportPosition >= 0 && viewportPosition <= 1) {
                        const verticalOffset = ((viewportPosition - 0.5) * 100) + 32;

                        shadowElement.css('transform', `translateY(${verticalOffset}px)`);
                    }
                });
            };

            $(window).on('scroll', () => {
                if (!ticking)
                    requestAnimationFrame(update);

                ticking = true;
            });

            update();
        }, 400);
    }
};

const init = () => {
    shadowAnimation('.section-heading');
};

export default init;
