import $ from 'jquery';

const init = () => {
    const sectionHeadings = $('.section-heading[data-id][data-icon]');

    if (sectionHeadings.length > 0) {
        const sideBarNavigation = $('.side-bar-navigation');
        const homeItem = $('.side-bar-navigation-item-home-template')[0].innerHTML;
        const itemTemplate = $('.side-bar-navigation-item-template')[0].innerHTML;

        sideBarNavigation.append(homeItem);

        sectionHeadings.each((index, element) => {
            const sectionElement = $(element);
            const icon = sectionElement.attr('data-icon');
            const title = sectionElement.find('.section-heading-text').text();
            const id = sectionElement.attr('data-id');
            const sectionNavHtml =
                itemTemplate
                    .replace(/\[icon]/g, icon)
                    .replace(/\[text]/g, title)
                    .replace(/\[id]/g, id);

            sideBarNavigation.append(sectionNavHtml);
        });
    } else
        $('.side-bar').addClass('empty');
};

export default init;
