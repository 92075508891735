/* eslint-disable no-underscore-dangle */
import { Popover } from 'bootstrap';
import $ from 'jquery';

const initializeErrorMessages = () => {
    const requiredErrorMessage = $('.contact-form').attr('data-required-error-message');
    const emailErrorMessage = $('.contact-form').attr('data-email-error-message');

    const defaultErrorMessages = {};

    if (requiredErrorMessage)
        defaultErrorMessages.required = requiredErrorMessage;

    if (emailErrorMessage)
        defaultErrorMessages.email = emailErrorMessage;

    if (Object.keys(defaultErrorMessages).length > 0)
        $.extend($.validator.messages, defaultErrorMessages);
};

const resetContactFormFields = () => {
    $('[name="name"]').val('');
    $('[name="email"]').val('');
    $('[name="enquiry"]').val('');
    $('[name="message"]').val('');
};

const init = () => {
    initializeErrorMessages();

    $('.contact-form').validate({
        errorPlacement: (error, element) => {
            const button = $(element).parent().find('.field-error-indicator');
            const popover = Popover.getInstance(button[0]);

            if (popover?._config?.content)
                popover._config.content = error.text();
        }
    });

    $('.contact-form').on('submit', (e) => {
        if ($('.contact-form').valid()) {
            $('.contact-form').hide();
            $('.contact-form-success').show();
        }

        e.preventDefault();
    });

    $('.contact-form-success').on('click', '.contact-retry-button', (e) => {
        $('.contact-form').show();
        $('.contact-form-success').hide();
        resetContactFormFields();

        e.preventDefault();
    });

    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    [ ...popoverTriggerList ].forEach((popoverTriggerEl) => new Popover(popoverTriggerEl));
};

export default init;
