import 'jquery';
import 'jquery-validation';
import 'slick-carousel';
import AOS from 'aos';
import { Dropdown } from 'bootstrap';

import CardsListInit from './components/cards-list';
import CarouselsInit from './components/carousels';
import ContactFormInit from './components/contact-form';
import FancySliderInit from './components/fancy-slider';
import FlakesInit from './components/flakes';
import Header from './components/header';
import HeroInit from './components/hero';
import 'magnific-popup/dist/jquery.magnific-popup';
import ModalInit from './components/modal';
// import NewsletterInit from './components/newsletter';
// import NewsletterFormInit from './components/newsletter-form';
// import './components/newsletter-form/mailchimp';
import SectionHeaderInit from './components/section-header';
import SideBarInit from './components/side-bar';
import VideoInit from './components/video';
import Global from './global';

document.addEventListener('DOMContentLoaded', () => {
    const dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'));
    dropdownElementList.map((dropdownToggleEl) => new Dropdown(dropdownToggleEl));

    AOS.init();

    new Global().init();
    new Header().init();
    CardsListInit();
    ContactFormInit();
    HeroInit();
    VideoInit();
    CarouselsInit();
    SectionHeaderInit();
    FancySliderInit();
    FlakesInit();
    // NewsletterInit();
    // NewsletterFormInit();
    SideBarInit();
    ModalInit();
});
