import { init as AOSInit } from 'aos';
import $ from 'jquery';

const addOnLoadAnimation = () => {
    $('.flake, .gridView>ul>li, .thumbs').each((index, element) => {
        $(element).attr('data-aos', 'zoom-in');
        $(element).attr('data-aos-delay', 100 + 200 * index);
    });

    $('.fellowslist>ul>li:nth-child(even)').each((index, element) => {
        $(element).attr('data-aos', 'fade-right');
        $(element).attr('data-aos-delay', 100 + 100 * index);
    });

    $('.fellowslist>ul>li:nth-child(odd)').each((index, element) => {
        $(element).attr('data-aos', 'fade-left');
        $(element).attr('data-aos-delay', 100 + 100 * index);
    });

    $([
        '.secExperience',
        '.secProSchedule',
        '.secInstafeed',
        '.secKnowledgeReports',
        '.brandslogos_widget',
        '.c-main-footer',
        '.secSpeakerSlider',
        '.special-fellowsSec',
        '.contactSec'
    ].join(', ')).each((index, element) => {
        $(element).attr('data-aos', 'fade-up');
    });
};

const init = () => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    const isMobile = window.innerWidth < 991;

    if (!mediaQuery.matches && !isMobile) {
        $(document).on('mousemove', document, (e) => {
            $('.flake').each((index, element) => {
                $(element).css({
                    transform: `translate(-${ Math.random(e.pageX) * 20 }%, -${ Math.random(e.pageY) * 15 }%)`,
                    transitionDelay: '0s'
                });
            });
        });
    }

    addOnLoadAnimation();
    AOSInit();
};

export default init;
